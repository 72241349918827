@use "./functions" as f;

// @mixin verticalCenter {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

@font-face {
  font-family: "font-1";
  src: url(../src/assets/fonts/fg_heavy.ttf);
}

@font-face {
  font-family: "font-2";
  src: url(../src/assets/fonts/Roboto-regular.ttf);
  // src: url(../src/assets/fonts/Roboto-thin.ttf);
}

$font-1: 'franklin-gothic', sans-serif;
$font-2: 'Roboto', sans-serif;

$black: rgb(39, 39, 39);
$blackhr: rgb(112, 112, 112);
$grey: #eaeaea;
$white: rgb(243, 243, 243);

html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

body {
  background-color: $grey;
  // @include verticalCenter;
}

h1, h2, h3, h4 {
  font-family: "font-1";
  color: $black;
}

h5, p {
  font-family: "font-2";
  color: $black;
}

// Explications for the typology size :
// f.rem is a function that convert (pixels) into rem
// First value is the lowest value autorized, second value is mix with third value, which is the viewport width, last value is the highest value autorized
h1 {
  font-size: clamp(f.rem(30), f.rem(20) + 5vw, f.rem(80));
  font-weight: 500;
}

h2 {
  font-size: clamp(f.rem(18), f.rem(10) + 5vw, f.rem(50));
  font-weight: 500;
}

h3 {
  font-size: clamp(f.rem(16), f.rem(5) + 5vw, f.rem(30));
  font-weight: 500;
}

h4 {
  font-size: clamp(f.rem(14), f.rem(4) + 1vw, f.rem(20));
  font-weight: 500;
}

h5 {
  font-size: clamp(f.rem(12), f.rem(4) + 1vw, f.rem(16));
  font-weight: 500;
}

p {
  font-size: clamp(f.rem(10), f.rem(2) + 1vw, f.rem(14));
  font-weight: 100;
}

a,
a:visited,
a:active {
  text-decoration: none;
  cursor: pointer;
}

li {
  list-style-type: none;
}

// hr {
//   border: 0.5px solid $blackhr;
// }