@import '../../_breakpoints';

.navigation {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    background-color: white;
    padding-inline: 150px;
    padding-block: 13px;

    @include breakpoint-down (medium) {
        padding-inline: 50px;
    }
}