@import '../../_breakpoints';

.work-details-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
    // background-color: rgb(39, 39, 39);

    @include breakpoint-down (medium) {
        flex-direction: column;
    }
}

.work-others-details-container {
    display: flex;
    flex-direction: column;

    @include breakpoint-down (medium) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.objectifs-container {
    width: 100%;

    @include breakpoint-down (medium) {
        width: 30%;
    }
}

.logiciels-container {
    width: 100%;

    @include breakpoint-down (medium) {
        width: 30%;
    }
}

.date-container {
    width: 100%;

    @include breakpoint-down (medium) {
        width: 30%;
    }
}

.title-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.work-details-description {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include breakpoint-down (medium) {
        width: 100%;
    }
    .work-others-details-container {
        display: flex;
        gap: 15px;
    }
}

.work-details-media {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;

    .video-container {
        overflow: hidden;
        max-width: 100%;
        width: 100%;
        height: 0; /* Set height to 0 to maintain aspect ratio */
        padding-bottom: 56.25%; /* 16:9 aspect ratio */
        position: relative;
        
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }
    }

    img {
        width: 48.6%;

        @include breakpoint-down (medium) {
            width: 100%;
        }
    }

    @include breakpoint-down (medium) {
        flex-direction: column;
    }
}