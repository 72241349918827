@import '../../_breakpoints';

* {
    box-sizing: border-box;
    cursor: url('/../src/assets/img/cursorBasic.webp'), auto;
}

a:hover {
    cursor: url('/../src/assets/img/cursorHand.webp'), auto;
}

header {
    position: fixed;
    width: 100%;
    z-index: 1;
}

main {
    padding-top: 100px;
    padding-inline: 150px;

    @include breakpoint-down (medium) {
        padding-inline: 50px;
    }

    .presentation-container {
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        align-items: center;
        margin: 200px 0 200px 0;
        gap: 50px;

        .presentation-text-container {
            width: 70%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            @include breakpoint-down (medium) {
                width: 80%;
                align-items: center;
            }
        }

        .face-size {
            width: 50%;

            @include breakpoint-down (medium) {
                width: 100%;
            }
        }

        @include breakpoint-down (medium) {
            flex-direction: column;
            align-items: center;
        }
    }

    .works-id hr {
        border: 0;
    }

    .works-container {
        margin-top: 100px;
    }

    .works-title {
        margin-bottom: 40px;
    }
}


footer {
    margin: 200px 0 100px 0;
}