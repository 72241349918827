@use "./functions" as f;

$breakpoints-down: (
    'small': f.em(575.98),
    'medium': f.em(767.98),
    'large': f.em(901.98),
    'xlarge': f.em(1439.98),
);

@mixin breakpoint-down($size) {
    @media only screen and (max-width: map-get($breakpoints-down, $size)) {
        @content;
    }
}

$breakpoints-up: (
    'small': f.em(576),
    'medium': f.em(768),
    'large': f.em(902),
    'xlarge': f.em(1440),
);

@mixin breakpoint-up($size) {
    @media only screen and (min-width: map-get($breakpoints-up, $size)) {
        @content;
    }
}