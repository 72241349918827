@import '../../_breakpoints';

.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 50px;

    img{
        width: 25%;

        @include breakpoint-down (medium) {
            width: 60%;
        }
    }
}