@import '../../_breakpoints';

.icon-navigation {
    width: min(100%, 45px);

    img {
        width: 100%;
        max-height: 45px;
        @include breakpoint-down (medium) {
            // height: 45px;
        }
    }

    img:hover {
        cursor: url('/../src/assets/img/cursorHand.webp'), auto;
    }
}

.width {
    width: 60px;
    @include breakpoint-down (small) {
        width: min(100%, 60px);
    }
}