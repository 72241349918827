@import '../../_breakpoints';

.icon-social-container {
    width: min(100%, 250px);
    display: flex;
    gap: 20px;

    @include breakpoint-down (medium) {
        width: 70%;
    }

    img {
        width: 100%;
    }
    
    img:hover {
        cursor: url('/../src/assets/img/cursorHand.webp'), auto;
    }
}